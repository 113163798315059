<template>
  <div id="product-list">
    <v-container class="secondary mt-10">
      <v-row>
        <v-col>
          <v-data-table :headers="headers" :items="products.items" :search="search">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="primary--text font-weight-bold">
                  Продукты
                </v-toolbar-title>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Вы точно хотите удалить этот продукт?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Отменить</v-btn
                      >
                      <v-btn
                        color="red darken-1"
                        text
                        @click="deleteItemConfirm"
                        >Удалить</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Поиск"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
              {{ returnDate(item) }}
            </template>
            <template v-slot:[`item.statusCode`]="{ item }">
              {{ returnStatus(item) }}
            </template>
            <template v-slot:[`item.productStatus`]="{ item }">
              {{ item.productStatus == 0 ? "На модерации" : "Готово" }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ProductList",

  data: () => ({
    search: "",
    dialogDelete: false,
    headers: [
      {
        text: "Наименование",
        align: "start",
        value: "name",
      },
      { text: "Штрих-код", value: "barCode" },
      { text: "Категория", value: "category" },
      { text: "Производитель", value: "account" },
      { text: "Бренд", value: "brand" },
      { text: "Статус", value: "productStatus", width: "14vh" },
      { text: "Состояние", value: "statusCode", width: "12vh" },
      { text: "Действия", value: "actions", sortable: false },
    ],
    products: [],
    error: null,
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http
        .get("api/v1/moderator/getmoderatorlist")
        .then((response) => {
          this.products = response.data.resultObject;
        })
        .catch((error) => (this.error = error));
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.products.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.$router.push({ path: `/admin/product/${item.productId}` });
    },

    returnDate(item) {
      var date = new Date(item.createdOn);
      return (
        ("0" + date.getDay()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear()
      );
    },

    returnStatus(item) {
      switch (item.statusCode) {
        case 1:
          return "активный";
        case 2:
          return "черновик";
        case 3:
          return "отключен";
        default:
          return "";
      }
    },
  },
};
</script>
